import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sauceToken: null,
  selectedEvent: null,
  selectedOrderId: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setSauceToken: (state, action) => {
      state.sauceToken = action.payload;
    },
    setSelectedEvent: (state, action) => {
      state.selectedEvent = action.payload;
    },
    setSelectedOrderId: (state, action) => {
      state.selectedOrderId = action.payload;
    },
  },
});

export const { setSauceToken, setSelectedEvent, setSelectedOrderId } =
  userSlice.actions;

export const getTicketSauceToken = (state) => state.user.sauceToken;
export const getSelectedEvent = (state) => state.user.selectedEvent;
export const getSelectedOrderId = (state) => state.user.selectedOrderId;
export default userSlice.reducer;
