import React, { Suspense } from "react";
import { lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { ThemeProvider } from "../context/ThemeProvider";
import { useSelector } from "react-redux";
import { getRole } from "../_store/_reducers/auth";
import { APP_ROLES } from "./../_helper";

const Login = lazy(() => import("../pages/login"));
const Register = lazy(() => import("../pages/register"));
const User = lazy(() => import("../pages/register/createuser"));
const Dashboard = lazy(() => import("../pages/dashboard"));
const Admin = lazy(() => import("../pages/superadmin/adminlogin"));
const AdminDashboard = lazy(() => import("../pages/superadmin"));
const ForgotPassword = lazy(() => import("../pages/forgotpassword"));
const BadgeDesign = lazy(() => import("../pages/badgedesign"));
const OrdersPage = lazy(() => import("../pages/orders"));
const AuthLayout = lazy(() => import("./../components/AuthLayout"));
const MainLayout = lazy(() => import("./../components/MainLayout"));
const Camera = lazy(() => import("./../pages/camera"));
const AppRouter = () => {
  const role = useSelector(getRole);
  return (
    <ThemeProvider>
      <BrowserRouter>
        <Suspense
          fallback={
            <div className="loading-loader-section">
              <Spinner animation="border" variant="primary" />
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<MainLayout />}>
              <Route index element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/createuser" element={<User />} />
              <Route path="/adminlogin" element={<Admin />} />
              <Route path="/forgot" element={<ForgotPassword />} />
            </Route>
            {/* AuthLayout for role-based routes */}
            <Route path={"dashboard"} element={<AuthLayout role={role} />}>
              {role === APP_ROLES.ADMIN && (
                <Route index element={<AdminDashboard />} />
              )}
              {role === APP_ROLES.USER && (
                <>
                  <Route index element={<Dashboard />} />
                  <Route path={`orders-list`} element={<OrdersPage />} />
                  <Route path={`badge-design`} element={<BadgeDesign />} />
                  <Route path={`cam`} element={<Camera />} />
                </>
              )}
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default AppRouter;
