import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  token: null,
  role: null,
  connectionId: null,
  isTemp: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true;
      state.token = action.payload.token;
      state.role = action.payload.role;
      state.isTemp = action.payload.isTemp || null;
      state.connectionId = action.payload.connectionId || null;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.token = null;
      state.role = null;
      state.isTemp = null;
      state.connectionId = null;
    },
  },
});

export const { login, logout } = authSlice.actions;

export const getIsAuthenticated = (state) => state.auth.isAuthenticated;
export const getToken = (state) => state.auth.token;
export const getRole = (state) => state.auth.role;
export const getConnectionId = (state) => state.auth.connectionId;
export const getisTemp = (state) => state.auth.isTemp;

export default authSlice.reducer;
